import serv from '../../assets/images/serv.png';
import klask from '../../assets/images/klask.png';
import miricyl from '../../assets/images/miricyl.png';
import React from 'react';
import ('./Projects.css');



function Projects() {
    return (
        <>
        <section id="projects" className='project'>
            <h2>Projects</h2>
            <hr></hr>
            <div className="project-container d-flex justify-content-center">
                <div className="project-card">
                    <div className="d-flex justify-content-center">
                        <img src={miricyl} className='miricyl img'></img>
                    </div>
                    <h3>Miricyl</h3>
                    <hr></hr>
                    <h4>Developed an application to help non profit organization to fight mental health problems </h4>
                    <p>Built using React</p>
                </div>
                <div className="project-card">
                    <div className='d-flex justify-content-center'>
                        <img src={serv} className='serv img'/>
                    </div>
                    <h3>Serv</h3>
                    <hr></hr>
                    <h4>Collaboratively built a project on a platform to connects MSMEs to service vendors digitally</h4>
                    <p>Built using Next.js and Tailwind. Designed with Figma </p>
                </div>

                <div className="project-card">
                    <div className='d-flex justify-content-center'>
                        <img src={klask} className='klask img'></img>
                    </div>
                    <h3>Klask</h3>
                    <hr></hr>
                    <h4>Klask is a board game like hockey table with two players playing against each other</h4>
                    <p>Built using Java</p>
                </div>
            </div>

        </section>
        </>
    );
}

export default Projects;