import React from "react";
import './Hero.css';
import Button from 'react-bootstrap/Button';


function Hero(){
    return (
        <>
        <section id="hero" className="hero">
            <div className="introduction">
                <div className="introduction-text">
                    <h1 className="introduction-name">Hello, my name is Afiq</h1>
                    <h2>I am a software developer</h2>
                    {/* <h1 className="introduction-role">I am a software developer</h1> */}
                </div>
                <div className="contact-button d-flex justify-content-sm-start justify-content-md-start">
                    <Button variant= "dark" size="lg" href="#contact">Contact Me</Button>
                </div>
            </div>
        </section>
        </>
    );
}

export default Hero;