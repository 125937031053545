import React from 'react';
import './Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faTwitter,
  faLinkedin,
  faGithub
} from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <>
      <footer className="footer d-flex align-self-center justify-content-center">
        <div className="footer-container d-flex align-self-center justify-content-center">
          <div className="footer-icon">
          {/* font awesome icon */}
            <a href="https://github.com/afiqwafi" target="_blank"><FontAwesomeIcon className="icon" icon={faGithub} size="lg"/></a>
            <a href="https://www.linkedin.com/in/muhammad-afiq-abdul-wafi-393ab1211/" target="_blank"><FontAwesomeIcon className="icon" icon={faLinkedin} size="lg"/></a>
            <a href="https://www.instagram.com/afiqabdulwafi/" target="_blank"><FontAwesomeIcon className="icon" icon={faInstagram} size="lg"/></a>
          </div>
          <div className="footer-text">
            <p className='copyright'>© 2022 Muhammad Afiq Abdul Wafi</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
