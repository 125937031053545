import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "./Oxo.css";

// function Oxo() {
//   return (
//     <>
//     <Navbar className = "nav-color" variant="dark">
//       <Container className ="nav-container">
//           <Navbar.Brand className="oxo" href="#home">
//             <h1>OXO</h1>
//           </Navbar.Brand>
//           <Nav className="justify-content-end">
//             <Nav.Item>
//               <Nav.Link className="nav-item" href="#home">Home</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link className="nav-item" href="#about">About</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link className="nav-item" href="#projects">Projects</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link className="nav-item" href="#contact">Contact</Nav.Link>
//             </Nav.Item>
//           </Nav>
//       </Container>
//     </Navbar>
//     </>
    /* <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </> */
//   );
// }

function Oxo() {
  return (
    <Navbar id="home" sticky="top" collapseOnSelect expand="lg" className="nav-color" variant="dark">
      <Container className="nav-container">
        <Navbar.Brand href="#hero">OXO</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="ms-auto">
            <Nav.Item>
                <Nav.Link className="nav-item" href="#hero">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-item" href="#about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-item" href="#projects">Projects</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-item" href="#contact">Contact</Nav.Link>
              </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Oxo;