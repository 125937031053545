import React from "react";

import Oxo from "./navbar/Oxo";
import Hero from "./hero/Hero";
import About from "./about/About";
import Projects from "./projects/Projects";
import Footer from "./footer/Footer";
import Contact from "./contact/Contact";

function App() {
    return (
        <>
            <Oxo />
            <Hero />
            <About />
            <Projects />
            <Contact />
            <Footer />
        </>
    );
}

export default App;