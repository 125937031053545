import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import Button from 'react-bootstrap/Button';

function Contact() {
    const form = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_wrqxppd', 'template_2l2raof', form.current, 'vmqKc9SahRJ2pgkYn')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <>
        <section id="contact">
            <h2>Contact Me</h2>
            <div className="contact">
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                    <label><h3>Name</h3></label>
                    <input className="input-text" type="text" name="user_name" />
                    <label><h3>Email</h3></label>
                    <input className="input-text" type="email" name="user_email" />
                    <label><h3>Message</h3></label>
                    <textarea name="message" />
                    <Button variant= "dark" size="lg" className='submit-btn'><input type="submit" value="Send" className='submit-input'/></Button>
                </form>
            </div>
        </section>
        </>
    );
}

export default Contact;

// import React from 'react';
// import { Form, Col, Button } from 'react-bootstrap';

// const initialFormData = Object.freeze({
//     username: "",
//     email: "",
//     mobile: "",
//     query: ""
//   });


// export const FormPage = (props) => {
//     const [formData, updateFormData] = React.useState(initialFormData);

//     const sendFeedback = (serviceID, templateId, variables) => {
//         window.emailjs.send(
//             serviceID, templateId,
//             variables
//         ).then(res => {
//             console.log('Email successfully sent!')
//         })
//             .catch(err => console.error('There has been an Error.', err))
//     }

//     const handleChange = (e) => {
//         updateFormData({
//           ...formData,

//           [e.target.name]: e.target.value.trim()
//         });
//       };

//     const handleSubmit = (e) => {
//         e.preventDefault()
//         alert(`Thank you for your message. Your query has been forwarded.`);
//         const templateId = 'template_2l2raof';
//         const serviceID = "service_wrqxppd";
//         sendFeedback(serviceID, templateId, { from_name: formData.name, mobile: formData.mobile, message_html: formData.query, email: formData.email })

//         console.log(formData);
//       };

//     return (

//         <Form>
//             <Form.Group as={Col} controlId="formGridName">
//                 <Form.Label>Name*</Form.Label>
//                 <Form.Control onChange= {handleChange} name="name" type="name" placeholder="Name" />
//             </Form.Group>

//             <Form.Group as={Col} controlId="formGridEmail">
//                 <Form.Label>Email*</Form.Label>
//                 <Form.Control onChange= {handleChange} name="email" type="email" placeholder="Enter email"
//                 />
//             </Form.Group>
//             <Form.Group as={Col} controlId="formGridMobile">
//                 <Form.Label>Mobile no.*</Form.Label>
//                 <Form.Control onChange= {handleChange} name="mobile" placeholder="" />
//             </Form.Group>
//             <Form.Group as={Col} id="formGridQuery">
//                 <Form.Label>Query*</Form.Label>
//                 <Form.Control onChange= {handleChange} name="query" as="textarea" rows={3} />
//             </Form.Group>

//             <Button onClick={handleSubmit} variant="primary" type="submit">
//                 Submit
//                 </Button>
//         </Form >

//     )
// }