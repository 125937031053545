import React from 'react';
import './About.css';
import me from '../../assets/images/me.JPG';


function About() {
  return (
    <>
    
      <section id="about" className="about">
        <div className ="profile d-flex justify-content-center align-self-center">
          <img src={me} className="profile-img"></img>
        </div>
        <h2 className="about-head">About</h2>
        <hr></hr>
        <h4 className="about-intro">
        A computer science student studying in Lancaster University.
        </h4>
        <h4 className="about-text">
        Born in Jakarta, Indonesia.
        <br></br>
        A tech enthusiast who like to learn new things.
        <br></br>
        I am in love with web designs and front-end development.
        </h4>
      </section>
    </>
  );
}

export default About; 